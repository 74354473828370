<template>
  <div class="home">
    <!-- <h2>wawentu</h2> -->
    <div style="height: 50px;">&nbsp;</div>
    <img src="../assets/logo-grande.svg" alt="wawentu" />
    <!-- <div>...</div> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    // HelloWorld
  }
}
</script>
